exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kredyty-index-tsx": () => import("./../../../src/pages/kredyty/index.tsx" /* webpackChunkName: "component---src-pages-kredyty-index-tsx" */),
  "component---src-pages-kredyty-kredyt-dla-firm-tsx": () => import("./../../../src/pages/kredyty/kredyt-dla-firm.tsx" /* webpackChunkName: "component---src-pages-kredyty-kredyt-dla-firm-tsx" */),
  "component---src-pages-kredyty-kredyt-gotowkowy-tsx": () => import("./../../../src/pages/kredyty/kredyt-gotowkowy.tsx" /* webpackChunkName: "component---src-pages-kredyty-kredyt-gotowkowy-tsx" */),
  "component---src-pages-kredyty-kredyt-hipoteczny-tsx": () => import("./../../../src/pages/kredyty/kredyt-hipoteczny.tsx" /* webpackChunkName: "component---src-pages-kredyty-kredyt-hipoteczny-tsx" */),
  "component---src-pages-kredyty-kredyt-konsolidacyjny-tsx": () => import("./../../../src/pages/kredyty/kredyt-konsolidacyjny.tsx" /* webpackChunkName: "component---src-pages-kredyty-kredyt-konsolidacyjny-tsx" */),
  "component---src-pages-kredyty-kredyt-refinansowy-tsx": () => import("./../../../src/pages/kredyty/kredyt-refinansowy.tsx" /* webpackChunkName: "component---src-pages-kredyty-kredyt-refinansowy-tsx" */),
  "component---src-pages-kredyty-pozyczka-hipoteczna-tsx": () => import("./../../../src/pages/kredyty/pozyczka-hipoteczna.tsx" /* webpackChunkName: "component---src-pages-kredyty-pozyczka-hipoteczna-tsx" */),
  "component---src-pages-o-firmie-tsx": () => import("./../../../src/pages/o-firmie.tsx" /* webpackChunkName: "component---src-pages-o-firmie-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-czy-mozna-kupic-nieruchomosc-z-kredytem-index-mdx": () => import("./../../../src/pages/porady/{mdx.frontmatter__slug}.tsx?__contentFilePath=C:/PROGRES/WEBDEV/ZLECENIA/MAXIM.COM.PL/w gatsbym/maximus/src/data/blog/czy-mozna-kupic-nieruchomosc-z-kredytem/index.mdx" /* webpackChunkName: "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-czy-mozna-kupic-nieruchomosc-z-kredytem-index-mdx" */),
  "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-dlaczego-warto-skladac-wnioski-w-kilku-bankach-jednoczesnie-index-mdx": () => import("./../../../src/pages/porady/{mdx.frontmatter__slug}.tsx?__contentFilePath=C:/PROGRES/WEBDEV/ZLECENIA/MAXIM.COM.PL/w gatsbym/maximus/src/data/blog/dlaczego-warto-składac-wnioski-w-kilku-bankach-jednoczesnie/index.mdx" /* webpackChunkName: "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-dlaczego-warto-skladac-wnioski-w-kilku-bankach-jednoczesnie-index-mdx" */),
  "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-jak-budowac-swoja-historie-kredytowa-dobre-praktyki-index-mdx": () => import("./../../../src/pages/porady/{mdx.frontmatter__slug}.tsx?__contentFilePath=C:/PROGRES/WEBDEV/ZLECENIA/MAXIM.COM.PL/w gatsbym/maximus/src/data/blog/jak-budowac-swoja-historie-kredytowa-dobre-praktyki/index.mdx" /* webpackChunkName: "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-jak-budowac-swoja-historie-kredytowa-dobre-praktyki-index-mdx" */),
  "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-mam-kredyt-jakb-bedzie-wygladal-zakup-index-mdx": () => import("./../../../src/pages/porady/{mdx.frontmatter__slug}.tsx?__contentFilePath=C:/PROGRES/WEBDEV/ZLECENIA/MAXIM.COM.PL/w gatsbym/maximus/src/data/blog/mam-kredyt-jakb-bedzie-wygladal-zakup/index.mdx" /* webpackChunkName: "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-mam-kredyt-jakb-bedzie-wygladal-zakup-index-mdx" */),
  "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-oprocentowanie-stale-czy-zmienne-index-mdx": () => import("./../../../src/pages/porady/{mdx.frontmatter__slug}.tsx?__contentFilePath=C:/PROGRES/WEBDEV/ZLECENIA/MAXIM.COM.PL/w gatsbym/maximus/src/data/blog/oprocentowanie–stale-czy-zmienne/index.mdx" /* webpackChunkName: "component---src-pages-porady-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-oprocentowanie-stale-czy-zmienne-index-mdx" */),
  "component---src-pages-porady-tsx": () => import("./../../../src/pages/porady.tsx" /* webpackChunkName: "component---src-pages-porady-tsx" */),
  "component---src-pages-ubezpieczenia-index-tsx": () => import("./../../../src/pages/ubezpieczenia/index.tsx" /* webpackChunkName: "component---src-pages-ubezpieczenia-index-tsx" */),
  "component---src-pages-ubezpieczenia-ubezpieczenie-do-kredytu-tsx": () => import("./../../../src/pages/ubezpieczenia/ubezpieczenie-do-kredytu.tsx" /* webpackChunkName: "component---src-pages-ubezpieczenia-ubezpieczenie-do-kredytu-tsx" */),
  "component---src-pages-ubezpieczenia-ubezpieczenie-na-zycie-tsx": () => import("./../../../src/pages/ubezpieczenia/ubezpieczenie-na-zycie.tsx" /* webpackChunkName: "component---src-pages-ubezpieczenia-ubezpieczenie-na-zycie-tsx" */),
  "component---src-pages-ubezpieczenia-ubezpieczenie-nieruchomosci-tsx": () => import("./../../../src/pages/ubezpieczenia/ubezpieczenie-nieruchomosci.tsx" /* webpackChunkName: "component---src-pages-ubezpieczenia-ubezpieczenie-nieruchomosci-tsx" */)
}

